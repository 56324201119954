/** Match url example: /en/football/europe/uefa-nations-league/serbia-spain-40531887 */
export const isMatchPageUrl = (url: string) => {
    if (!url) return false

    const urlSplit = url.split('/')
    if (urlSplit.length !== 6) return false

    // Match id consists of 3 parts, with number in the end
    const idStringArr = urlSplit[5].split('-')
    return idStringArr.length > 2 && Number.isInteger(Number(idStringArr[idStringArr.length - 1]))
}