import {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';
import {useDispatch} from 'react-redux';
import {saveRouteChange} from '../features/app';
import {useTranslation} from 'next-i18next';
import { isMatchPageUrl } from '../utils'

export const useRoutePrevious = () => {
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    const {events, asPath, locale} = useRouter()

    const refRouteCurrent = useRef('/' + locale + asPath)
    const refRoutePrev = useRef('')
    const refIsLangChanged = useRef(false)

    useEffect(() => {
        const onLangChange = () => {
            refIsLangChanged.current = true
        }

        const onRouteChange = (url: string) => {
            // Locale update happens with router event, when you have to replace lang-sensitive url on new from backend
            // -> you should skip routePrev update in that case.
            // Ex: open match page -> change locale -> without lang check old url with only new locale will be saved
            // -> you can't close match page by control 'X' and go to main page, as routePrev replaced.
            if (refIsLangChanged.current) {
                refIsLangChanged.current = false
                return
            }

            if (refRouteCurrent.current === url) return

            // Prevent RoutePrev update, if redirect happens from MatchPage to MatchPage,
            // or it'll be un-possible to return back to initial page on match sidebar close.
            // Ex: /en -> /match-1 -> /match-2 | (close sidebar)[x] -> stucked between /match-1 and /match-2.
            if (isMatchPageUrl(refRouteCurrent.current) && isMatchPageUrl(url)) return

            refRoutePrev.current = refRouteCurrent.current
            refRouteCurrent.current = url
            dispatch(saveRouteChange(refRoutePrev.current))
        }

        i18n.on('languageChanged', onLangChange)
        events.on('routeChangeComplete', onRouteChange)

        return () => {
            i18n.off('languageChanged', onLangChange)
            events.off('routeChangeComplete', onRouteChange)
        }
    }, [])
}