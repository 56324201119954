import { createReducer } from '@reduxjs/toolkit'
import {MatchStandingsFetched} from '../../api';
import {
    getMatchday,
    getMatches,
    getMatch,
    getMatchDetails,
    getMatchEvents,
    setTypeBlock,
    getMatchOdds,
    getForecasts,
    getMatchesCompetition,
    getOddsPopup,
    getMatchStandings,
    saveMatch,
    resetMatchIdSelection,
} from './actions'

type StatusAction = 'idle' | 'loading' | 'succeeded' | 'failed'
type TypeMatch = 'matchday' | 'match'

export type MatchesState = {
    match: null
    matchIdSelected: number | null,
    matches: any
    matchDetails: any
    matchEvents: any
    type: TypeMatch
    odds: any
    meta: any
    oddsPopup: any
    forecasts: any
    standings: MatchStandingsFetched | null
    status: {
        match: StatusAction
        matches: StatusAction
        matchDetails: StatusAction
        matchEvents: StatusAction
        odds: StatusAction
        forecasts: StatusAction
        competitionMatches: StatusAction
        oddsPopup: StatusAction
        standings: StatusAction
    }
    args: any
}

const initialState: MatchesState = {
    match: null,
    matchIdSelected: null,
    matchDetails: null,
    type: 'matchday',
    matchEvents: null,
    odds: null,
    meta: {},
    oddsPopup: null,
    forecasts: [],
    args: null,
    matches: {
        competitions: [],
    },
    standings: null,
    status: {
        match: 'idle',
        matches: 'idle',
        matchDetails: 'idle',
        matchEvents: 'idle',
        odds: 'idle',
        forecasts: 'idle',
        competitionMatches: 'idle',
        oddsPopup: 'idle',
        standings: 'idle',
    },
}

export const matchesReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(setTypeBlock.type, (state, action: any) => {
            // "mutate" the array by calling push()
            state.type = action?.payload as any
        })
        .addCase(saveMatch, (state, action) => {
            state.status.match = 'succeeded'
            state.match = action.payload.data
            state.meta = action.payload.meta;
        })
        .addCase(resetMatchIdSelection, (state) => {
            state.matchIdSelected = null
        })
        .addCase(getMatch.pending, (state) => {
            state.type = 'match'
            state.status.match = 'loading'
        })
        .addCase(getMatch.fulfilled, (state, action) => {
            state.status.match = 'succeeded'
            state.match = action.payload.data
            state.meta = action.payload.meta;
        })
        .addCase(getMatch.rejected, (state, action) => {
            state.status.match = 'failed'
        })
        .addCase(getMatchday.pending, (state, action) => {
            state.status.match = 'loading'
            state.type = 'matchday'
        })
        .addCase(getMatchday.fulfilled, (state, action) => {
            state.status.match = 'succeeded'
            state.match = action.payload.data
        })
        .addCase(getMatchday.rejected, (state, action) => {
            state.status.match = 'failed'
            state.type = 'matchday'
        })
        .addCase(getMatches.pending, (state, action) => {
            state.status.matches = 'loading'
        })
        .addCase(getMatches.fulfilled, (state, action) => {
            state.status.matches = 'succeeded'
            state.matches = {
                sport: action.payload.data.sport,
                odds_types: action.payload.data.odds_types,
                odds_selects: action.payload.data.odds_selects,
                competitions: action.payload.data.competitions,
            }
        })
        .addCase(getMatches.rejected, (state, action) => {
            state.status.matches = 'failed'
        })
        .addCase(getMatchDetails.pending, (state, action) => {
            state.status.matchDetails = 'loading'
            state.type = 'match'
        })
        .addCase(getMatchDetails.fulfilled, (state, action) => {
            state.status.matchDetails = 'succeeded'
            state.matchDetails = action.payload.data
        })
        .addCase(getMatchDetails.rejected, (state, action) => {
            state.status.matchDetails = 'failed'
        })
        .addCase(getMatchEvents.pending, (state, action) => {
            state.status.matchEvents = 'loading'
            state.type = 'match'
        })
        .addCase(getMatchEvents.fulfilled, (state, action) => {
            state.status.matchEvents = 'succeeded'
            state.matchEvents = action.payload.data.incidents
        })
        .addCase(getMatchEvents.rejected, (state, action) => {
            state.status.matchEvents = 'failed'
        })
        .addCase(getMatchOdds.pending, (state, action) => {
            state.status.odds = 'loading'
            state.type = 'match'
        })
        .addCase(getMatchOdds.fulfilled, (state, action) => {
            state.status.odds = 'succeeded'
            state.odds = action.payload.data
        })
        .addCase(getMatchOdds.rejected, (state, action) => {
            state.status.odds = 'failed'
        })
        .addCase(getForecasts.pending, (state, action) => {
            state.status.forecasts = 'loading'
        })
        .addCase(getForecasts.fulfilled, (state, action) => {
            state.status.forecasts = 'succeeded'
            state.forecasts = action.payload.data.predictions
        })
        .addCase(getForecasts.rejected, (state, action) => {
            state.status.forecasts = 'failed'
        })
        .addCase(getMatchesCompetition.pending, (state, action) => {
            state.status.competitionMatches = 'loading'
        })
        .addCase(getMatchesCompetition.fulfilled, (state, action) => {
            state.status.competitionMatches = 'succeeded'
        })
        .addCase(getMatchesCompetition.rejected, (state, action) => {
            state.status.competitionMatches = 'failed'
        })
        .addCase(getOddsPopup.pending, (state, action) => {
            state.status.oddsPopup = 'loading'
        })
        .addCase(getOddsPopup.fulfilled, (state, action) => {
            state.status.oddsPopup = 'succeeded'
            state.oddsPopup = action.payload.data;
            // set bufer
            // meta.arg
            state.args = action.meta.arg;
        })
        .addCase(getOddsPopup.rejected, (state, action) => {
            state.status.oddsPopup = 'failed'
        })
        // Match Standings
        .addCase(getMatchStandings.pending, (state) => {
            state.status.standings = 'loading'
        })
        .addCase(getMatchStandings.fulfilled, (state, action) => {
            state.status.standings = 'succeeded'
            state.standings = action.payload.data;
        })
        .addCase(getMatchStandings.rejected, (state) => {
            state.status.standings = 'failed'
        })
)

export default matchesReducer
