import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {HYDRATE} from 'next-redux-wrapper'
import {RootState} from '../../store'

type MatchId = number | null
type PageTypeFallback = 'main' | 'match' | 'fifa' | string

/** Данные для страницы/сайдбара выбранного матча. */
const matchSlice = createSlice({
    name: 'match',
    initialState: {
        matchId: null as MatchId,
        // Флаг сброса id матча
        resetMatchId: false,
        // Страница для перехода после закрытия матча <- сохраняем последнюю перед открытием матча страницу
        matchCloseFallback: 'main' as PageTypeFallback,
    },
    reducers: {
        setMatchId: (state, {payload}: PayloadAction<MatchId>) => {
            state.matchId = payload
        },
        setMatchIdReset: (state, {payload}: PayloadAction<boolean>) => {
            state.resetMatchId = payload
        },
        setMatchCloseFallback: (state, {payload}: PayloadAction<PageTypeFallback>) => {
            state.matchCloseFallback = payload
        },
    },
    // Init values from SSR
    extraReducers: {
        [HYDRATE]: (state, action) => {
            const {
                matchId,
                resetMatchId,
                matchCloseFallback,
            } = (action.payload as RootState).match

            if (resetMatchId) {
                state.matchId = null
                state.resetMatchId = false
                state.matchCloseFallback = matchCloseFallback
                return
            }

            // Если страница не менялась - ничего не сбрасываем
            if (matchCloseFallback === state.matchCloseFallback) return

            state.matchId = matchId

            // Страница матча не может быть fallback-ом при закрытии матча
            if (matchCloseFallback !== 'match')
                state.matchCloseFallback = matchCloseFallback
        },
    },
})

export const {
    setMatchId,
    setMatchIdReset,
    setMatchCloseFallback,
} = matchSlice.actions

export const selectMatchId = (state: RootState) => state.match.matchId

export const {reducer: reducerMatch} =  matchSlice