import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

export const useRouteIsChanging = () => {
    const {events} = useRouter()
    const refIsRouteChanging = useRef(false)

    useEffect(() => {
        function onRouteChangeStart() {
            refIsRouteChanging.current = true
        }
        function onRouteChangeComplete() {
            refIsRouteChanging.current = false
        }

        events.on('routeChangeStart', onRouteChangeStart)
        events.on('routeChangeComplete', onRouteChangeComplete)

        return () => {
            events.off('routeChangeStart', onRouteChangeStart)
            events.off('routeChangeComplete', onRouteChangeComplete)
        }
    }, [])

    return refIsRouteChanging
}